(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['skins.viewer.svgshape.SvgShapeDefaultSkin'] = {
  "react": [],
  "params": {
    "fillcolor": "COLOR",
    "alpha-fillcolor": "ALPHA",
    "stroke": "BORDER_COLOR_ALPHA",
    "strokewidth": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "fillcolor": "color_11",
    "alpha-fillcolor": "1",
    "stroke": "color_15",
    "strokewidth": "1px"
  },
  "css": {
    "% svg": "width:100%;height:100%;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;fill:[fillcolor];fill-opacity:[alpha-fillcolor];stroke:[stroke];stroke-width:[strokewidth];",
    "% svg *": "vector-effect:non-scaling-stroke;",
    "%_with-shadow svg": "overflow:visible !important;",
    "% a": "display:block;height:100%;"
  }
}
 skins['skins.viewer.VectorImageShapeBasicSkin'] = {
  "react": [
    [
      "div",
      "svg",
      [],
      {}
    ],
    [
      "a",
      "link",
      [],
      {}
    ]
  ],
  "params": {
    "fillcolor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "fillcolor": "color_18"
  },
  "css": {
    "% svg": "width:100%;height:100%;fill:[fillcolor];",
    "% svg *": "vector-effect:non-scaling-stroke;",
    "%_with-shadow svg": "overflow:visible !important;"
  }
}
 skins['skins.viewer.VectorImageSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "svg",
        [],
        {}
      ]
    ]
  ],
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%link,%svg": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "% svg": "position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;margin:auto;",
    "%_non-scaling-stroke *": "vector-effect:non-scaling-stroke;",
    "%_with-shadow svg": "overflow:visible !important;"
  }
}

        return skins;
    }));